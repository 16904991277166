import React from 'react'

import { container, Service } from 'Services/container'
import { ILocalStorageService } from 'Services/localStorage'
import { IProfileService, UtmAnalytics } from 'Services/profile'
import { useAuthWithDemo } from 'Demo'

const SESSION_UTM_KEY = 'SESSION_UTM'
const SESSION_UTM_TTL_KEY = 'SESSION_UTM_TTL'
const SESSION_UTM_STATUS_KEY = 'SESSION_UTM_STATUS'
const SESSION_UTM_STATUS = (userKey?: string) => `${SESSION_UTM_STATUS_KEY}_${userKey}`
const TTL = 7 * 24 * 60 * 60 * 1000

const UTM_SOURCE = 'utm_source'
const UTM_MEDIUM = 'utm_medium'
const UTM_CAMPAIGN = 'utm_campaign'
const UTM_CONTENT = 'utm_content'
const UTM_TERM = 'utm_term'

export const useUtmAnalytics = () => {
  const localStorage = container.resolve<ILocalStorageService>(Service.LocalStorageService)
  const profileService = container.resolve<IProfileService>(Service.ProfileService)

  const { getAccessTokenWithDemo, user } = useAuthWithDemo()
  const now = Date.now()

  const cleanExpiredUtmParams = () => {
    localStorage.removeItem(SESSION_UTM_KEY)
    localStorage.removeItem(SESSION_UTM_TTL_KEY)

    localStorage.keys().forEach(key => {
      if (key.startsWith(SESSION_UTM_STATUS_KEY)) {
        localStorage.removeItem(key)
      }
    })
  }

  const saveUtm = () => {
    const searchParams = new URLSearchParams(window.location.search)

    const utmSource = searchParams.get(UTM_SOURCE)
    const utmMedium = searchParams.get(UTM_MEDIUM)
    const utmCampaign = searchParams.get(UTM_CAMPAIGN)
    const utmContent = searchParams.get(UTM_CONTENT)
    const utmTerm = searchParams.get(UTM_TERM)

    const atLeastOneUtm = utmSource || utmMedium || utmCampaign || utmContent || utmTerm

    if (!atLeastOneUtm) {
      return
    }

    const newUtmObject = { utmSource, utmMedium, utmCampaign, utmContent, utmTerm }
    const existingUtmObject = localStorage.getItem<UtmAnalytics>(SESSION_UTM_KEY)

    if (JSON.stringify(newUtmObject) !== JSON.stringify(existingUtmObject)) {
      cleanExpiredUtmParams()

      localStorage.setItem(SESSION_UTM_KEY, newUtmObject)
      localStorage.setItem(SESSION_UTM_TTL_KEY, now)
    }
  }

  const getUtm = () => {
    const utmObject = localStorage.getItem<UtmAnalytics>(SESSION_UTM_KEY)
    const utmTtl = localStorage.getItem<number>(SESSION_UTM_TTL_KEY) ?? 0

    if (now - utmTtl < TTL) {
      return utmObject
    }

    cleanExpiredUtmParams()

    return null
  }

  const sendUtmAnalytics = () => {
    try {
      const utm = getUtm()
      const userStatusKey = SESSION_UTM_STATUS(user?.sub)

      if (!localStorage.getItem(userStatusKey) && utm) {
        getAccessTokenWithDemo()
          .then(token => profileService.saveUserAnalytics(token, utm))
          .then(() => localStorage.setItem(userStatusKey, true))
      }
    } catch {
      cleanExpiredUtmParams()
    }
  }

  React.useEffect(() => {
    try {
      saveUtm()
    } catch {
      cleanExpiredUtmParams()
    }
  }, [ ])

  return {
    sendUtmAnalytics,
  }
}
