import React from 'react'
import { styled, Chip, ChipProps } from '@mui/material'

import { CrownIcon } from 'Shared/assets'

interface Props {
  text: string
}

const StyledCrownIcon = styled(CrownIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  width: 12,
  height: 12,
}))

const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.premium.main,
  fontWeight: 'bold',
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1),
  width: 'fit-content',
}))

export const PremiumChip: React.FC<ChipProps> = props => (
  <StyledChip size="small" icon={<StyledCrownIcon />} {...props} />
)
