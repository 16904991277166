import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from 'Shared/constants'

export const SubscribeButton: React.FC<ButtonProps> = props => {
  const { t } = useTranslation()

  return (
    <Button
      size="small"
      variant="contained"
      component={Link}
      to={Routes.PRICING}
      {...props}
    >
      {t('common.subscribe')}
    </Button>
  )
}
