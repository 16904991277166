import i18next from 'config/i18n'
import {
  PortfolioAllocationType,
  IPortfolioService,
  ChangeSunburstAllocation,
  VisibilitySettingsRequest,
  VisibilitySettingsResponse
} from 'Services/portfolio'
import { DemoOperationWarning } from 'Demo/demo-constants'

export class DemoPortfolioService implements IPortfolioService {
  public getVisibilitySettings(token: string): Promise<VisibilitySettingsResponse> {
    return Promise.resolve({ portfolioKey: 'demo', visibleHoldingColumns: [] })
  }

  public updateVisibilitySettings(token: string, request: VisibilitySettingsRequest): Promise<void> {
    return Promise.resolve()
  }

  public getAssetAllocation(token: string, type: PortfolioAllocationType) {
    return import(`./allocations/${type}`)
      .then(response => response.default)
      .then(response => {
        if (type === PortfolioAllocationType.Category) {
          return {
            allocation: Object
              .keys(response.allocation)
              .reduce(
                (acc, key) => ({ ...acc, [ i18next.t(key, { ns: 'demo' }) ]: response.allocation[ key ] }),
                {})
          }
        }
        return response
      })
  }

  public updateAssetAllocation(token: string, type: PortfolioAllocationType, request: any) {
    return Promise.reject(DemoOperationWarning)
  }

  public deletePortfolios(token: string) {
    return Promise.reject(DemoOperationWarning)
  }

  public getMyAssetAllocation(token: string, type: PortfolioAllocationType): any {
    return Promise.resolve()
  }

  public updateMyAssetAllocation(token: string, type: PortfolioAllocationType, request: any): any {
    return Promise.resolve()
  }

  public changeSunburstAllocation(token: string, firstLevelProperty: string, secondLevelProperty: string, request: ChangeSunburstAllocation): any {
    return Promise.resolve()
  }

  public resetSunburstAllocation(token: string, firstLevelProperty: string, secondLevelProperty: string): any {
    return Promise.resolve()
  }

  public importPortfolio(token: string, file: FormData): any {
    return Promise.resolve()
  }
}
