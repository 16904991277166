import { all, call, put, select, takeEvery, takeLatest, debounce } from 'redux-saga/effects'

import { container, Service } from 'Services/container'
import { IHoldingsService, Summary, Holding } from 'Services/holdings'
import { IPlaidService } from 'Services/plaid'
import { IPortfolioService } from 'Services/portfolio'
import { VisibilitySettingsResponse } from 'Services/portfolio/portfolio-types'
import { snackbarActionCreators } from 'Snackbar'
import type { ITypedActionWithToken, IActionWithToken } from 'Shared/types'
import { HoldingActions } from './holdings-actions'
import { HoldingsActionTypes } from './holdings-constants'
import { HoldingSelectors } from './holdings-selectors'
import { ArchiveHoldingSpecification } from './holdings-specifications'

export function* getViewSettings({ token }: IActionWithToken) {
  const portfolioService = container.resolve<IPortfolioService>(Service.PortfolioService)

  try {
    const response: VisibilitySettingsResponse = yield call(portfolioService.getVisibilitySettings, token)
    yield put(HoldingActions.setColumnViewSettingsSuccess(response.visibleHoldingColumns ?? []))
  } catch (error) {
    yield put(HoldingActions.setColumnViewSettingsSuccess([]))
  }
}

export function* syncViewSettings({ token }: IActionWithToken) {
  const visibleHoldingColumns: string[] = yield select(HoldingSelectors.getColumnViewSettings)

  try {
    const portfolioService = container.resolve<IPortfolioService>(Service.PortfolioService)
    yield call(portfolioService.updateVisibilitySettings, token, { visibleHoldingColumns })
    yield put(snackbarActionCreators.enqueueSuccessSnackbar('holdings.viewSettings.success'))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.viewSettings.failure'))
  }
}

export function* getHoldings({ token }: IActionWithToken) {
  const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)

  try {
    const response: Holding[] = yield call(holdingsService.getHoldings, token)
    yield put(HoldingActions.getHoldingsSuccess(response))
  } catch (error) {
    yield put(HoldingActions.getHoldingsFailure())
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.table.loading-failure'))
  }
}

export function* getSummary({ token }: IActionWithToken) {
  const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)

  try {
    const response: Summary = yield call(holdingsService.getSummary, token)
    yield put(HoldingActions.getSummarySuccess(response))
  } catch (error) {
    yield put(HoldingActions.getSummaryFailure())
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.summary.loading-failure'))
  }
}

function* archiveHolding({ payload, token }: ITypedActionWithToken<string>) {
  try {
    const holdingToArchive: Holding | null = yield select(HoldingSelectors.holdingSelector, payload)

    if (!holdingToArchive) { return }

    const specification = new ArchiveHoldingSpecification(holdingToArchive)
    const specificationResult = specification.verify()

    if (!specificationResult.isValid) {
      yield put(snackbarActionCreators.enqueueWarningSnackbar(specificationResult.errorKey))
      return
    }

    const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)
    yield call(holdingsService.archiveHolding, payload, token)

    yield put(HoldingActions.getHoldings(token))
    yield put(HoldingActions.getSummary(token))
    yield put(snackbarActionCreators.enqueueSuccessSnackbar('holdings.manage.archive-success', { holding: holdingToArchive.asset.name }))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.archive-failure'))
  }
}

function* deleteHolding({ payload, token }: ITypedActionWithToken<string>) {
  try {
    const holdingToDelete: Holding | null = yield select(HoldingSelectors.holdingSelector, payload)

    if (!holdingToDelete) { return }

    const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)
    yield call(holdingsService.deleteHolding, payload, token)

    yield put(HoldingActions.getHoldings(token))
    yield put(HoldingActions.getSummary(token))
    yield put(snackbarActionCreators.enqueueSuccessSnackbar('holdings.manage.delete-success', { holding: holdingToDelete.asset.name }))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.delete-failure'))
  }
}

function* pinHolding({ payload, token }: ITypedActionWithToken<string>) {
  try {
    const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)
    yield call(holdingsService.pinHolding, payload, token)
    const pinDate = new Date().toISOString()
    const data = { key: payload, pinDate }
    yield put(HoldingActions.pinHoldingSuccess(data))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.pin-failure'))
  }
}

function* disconnectHolding({ payload, token }: ITypedActionWithToken<string>) {
  const selectedHolding: Holding | null = yield select(HoldingSelectors.holdingSelector, payload)

  if (!selectedHolding) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.disconnect-failure'))
    return
  }

  try{
    const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)
    yield call(holdingsService.disconnectHolding, payload, token)

    yield put(HoldingActions.getHoldings(token))
    yield put(HoldingActions.drawers.disconnectAccount.close())
    yield put(snackbarActionCreators.enqueueSuccessSnackbar('holdings.manage.disconnect-success', { holding: selectedHolding.asset.name }))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.disconnect-failure'))
  }
}

function* refreshHolding({ payload, token }: ITypedActionWithToken<string>) {
  const selectedHolding: Holding | null = yield select(HoldingSelectors.holdingSelector, payload)

  if (!selectedHolding || !selectedHolding.externalConnection) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.refresh-failure'))
    return
  }

  try {
    const plaidService = container.resolve<IPlaidService>(Service.PlaidService)
    yield call(plaidService.refreshConnection, selectedHolding.externalConnection.key, token)
    yield put(HoldingActions.getHoldings(token))
    yield put(snackbarActionCreators.enqueueSuccessSnackbar('holdings.manage.refresh-success', { holding: selectedHolding.asset.name }))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.refresh-failure'))
  }
}

function* resumeHolding({ payload, token }: ITypedActionWithToken<string>) {
  const selectedHolding: Holding | null = yield select(HoldingSelectors.holdingSelector, payload)

  if (!selectedHolding || !selectedHolding.externalConnection) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.refresh-failure'))
    return
  }

  try {
    const plaidService = container.resolve<IPlaidService>(Service.PlaidService)
    yield call(plaidService.resumeConnection, selectedHolding.externalConnection.key, token)
    yield put(HoldingActions.getHoldings(token))
    yield put(snackbarActionCreators.enqueueSuccessSnackbar('holdings.manage.resume-success', { holding: selectedHolding.asset.name }))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.resume-failure'))
  }
}

export function* holdingsSaga() {
  yield all([
    takeLatest(HoldingsActionTypes.GET_HOLDINGS, getHoldings),
    takeLatest(HoldingsActionTypes.GET_SUMMARY, getSummary),
    takeEvery(HoldingsActionTypes.ARCHIVE_HOLDING, archiveHolding),
    takeEvery(HoldingsActionTypes.DELETE_HOLDING, deleteHolding),
    takeEvery(HoldingsActionTypes.TOGGLE_PIN_HOLDING, pinHolding),
    takeEvery(HoldingsActionTypes.DISCONNECT_HOLDING, disconnectHolding),
    takeEvery(HoldingsActionTypes.REFRESH_HOLDING, refreshHolding),
    takeEvery(HoldingsActionTypes.RESUME_HOLDING, resumeHolding),
    takeLatest(HoldingsActionTypes.GET_VIEW_SETTINGS, getViewSettings),
  ])
  yield debounce(2000, HoldingsActionTypes.SYNC_COLUMN_VIEW_SETTING, syncViewSettings)
}
