import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Button } from '@mui/material'

import { RenewalPeriod } from 'Services/profile'
import { useSnackbar } from 'Snackbar'
import { useFeatureFlag } from 'Shared/hooks'
import { TypedDictionary } from 'Shared/types'
import { GoogleTagManagerEvent } from 'Shared/constants'
import {
  FreePricingCardConfiguration,
  PremiumPricingCardConfiguration,
  FreePricingPlan, AdvisorPricingCardConfiguration
} from 'Pricing/pricing-constants'
import { PricingPlan } from 'Pricing/pricing-types'
import { PricingCard } from './PricingCard'

interface Props {
  premiumPricingPlan: TypedDictionary<typeof RenewalPeriod, PricingPlan>
  renewalPeriod: RenewalPeriod
  handlePremiumPlanSelected: (renewalPeriod: RenewalPeriod) => void
  disabled: boolean
}

export const PricingPageCards: React.FC<Props> = ({
  premiumPricingPlan,
  renewalPeriod,
  handlePremiumPlanSelected,
  disabled
}) => {
  const { t } = useTranslation()
  const { enqueueSuccess } = useSnackbar()
  const daySubscriptionFeatureFlag = useFeatureFlag('daySubscription')

  const handleShareWithAdvisor = async () => {
    if (navigator.clipboard) {
      window.dataLayer.push({ event: GoogleTagManagerEvent.shareWithAdvisorClick })
      await navigator.clipboard.writeText(t('pricing.plans.advisor-shareText'))
      enqueueSuccess(t('pricing.plans.advisor-shareMessage'))
    }
  }

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} sm={6} md={4}>
        <PricingCard
          title={t('pricing.plans.free')}
          forWhomText={t('pricing.plans.free-forWhom')}
          choseButtonText={t('pricing.currentPlan')}
          pricingPlan={FreePricingPlan[renewalPeriod]}
          localePath={FreePricingCardConfiguration.localePath}
          featureKeys={FreePricingCardConfiguration.features}
          hintKeys={FreePricingCardConfiguration.hints}
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <PricingCard
          title={t('pricing.plans.premium')}
          forWhomText={t('pricing.plans.premium-forWhom')}
          choseButtonText={t('pricing.upgrade')}
          pricingPlan={premiumPricingPlan[renewalPeriod]}
          localePath={PremiumPricingCardConfiguration.localePath}
          featureKeys={PremiumPricingCardConfiguration.features}
          hintKeys={PremiumPricingCardConfiguration.hints}
          onChosePlan={() => handlePremiumPlanSelected(renewalPeriod)}
          disabled={disabled}
          highlighted
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <PricingCard
          title={t('pricing.plans.advisor')}
          forWhomText={t('pricing.plans.advisor-forWhom')}
          choseButtonText={t('pricing.plans.advisor-cta')}
          pricingPlan={FreePricingPlan[renewalPeriod]}
          localePath={AdvisorPricingCardConfiguration.localePath}
          featureKeys={AdvisorPricingCardConfiguration.features}
          hintKeys={AdvisorPricingCardConfiguration.hints}
          onChosePlan={handleShareWithAdvisor}
        />
      </Grid>

      {daySubscriptionFeatureFlag.activated && (
        <Grid item xs={12}>
          <Button onClick={() => handlePremiumPlanSelected(RenewalPeriod.Day)}>Daily subscription</Button>
        </Grid>
      )}
    </Grid>
  )
}
