import React from 'react'
import { styled, Tooltip as MuiTooltip, tooltipClasses, TooltipProps } from '@mui/material'

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.blue.blue3}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
    maxWidth: 350,
  },
}))
