import React from 'react'
import { useDispatch } from 'react-redux'

import { container, Service } from 'Services/container'
import { IProfileService, UserProfile } from 'Services/profile'
import { PortfolioAllocationType } from 'Services/portfolio'
import { Routes } from 'Shared/constants'
import { Nullable } from 'Shared/types'
import { useTypedDrawer, useDrawer } from 'Shared/hooks'
import { useAuthWithDemo, DemoActions } from 'Demo'
import type { SettingsState } from 'App/Settings/settings-types'
import { DefaultSettingsState } from 'App/Settings/settings-constants'
import { StepName } from 'App/UserGuide/types'
import { useTasksManager } from 'App/Tasks'
import { Permission, extractAuthorizationProfile, hasAccess } from 'Integration/authorization'
import { useHiddenMode } from './useHiddenMode'
import { useUserGuide } from './useUserGuide'
import { useAppBarSettings } from './useAppBarSettings'
import { usePromoCode } from './usePromoCode'
import { useUtmAnalytics } from './useUtmAnalytics'

export const useSettings = (isDemoModeEnabled: boolean): SettingsState => {
  const dispatch = useDispatch()

  const { isAuthenticated, getAccessTokenWithDemo } = useAuthWithDemo()
  const { isHiddenModeEnabled, toggleHiddenMode, setHiddenMode } = useHiddenMode()
  const { promoCode, clearPromoCode, savePromoCode } = usePromoCode()
  const { sendUtmAnalytics } = useUtmAnalytics()
  const [ essentialsLoaded, setEssentialsLoaded ] = React.useState(false)
  const [ profile, setProfile ] = React.useState<Nullable<UserProfile>>(null)

  const [ releaseNotesRead, setReleaseNotesRead ] = React.useState<boolean>(DefaultSettingsState.manageSettings.releaseNotesDialog.releaseNotesRead)
  const { appBarExpanded, toggleAppBar } = useAppBarSettings()
  const [ baseCurrency, setBaseCurrency ] = React.useState<string>(DefaultSettingsState.baseCurrency)
  const [ pendingSurveys, setPendingSurveys ] = React.useState<string[]>(DefaultSettingsState.pendingSurveys)

  const assetAllocationDrawer = useTypedDrawer<PortfolioAllocationType>()
  const myHistoryDrawer = useDrawer()
  const myTasksDrawer = useDrawer()
  const sharedHistoryDrawer = useTypedDrawer<string>()
  const releaseNotesDialog = useTypedDrawer<boolean>()
  const settingsDrawer = useDrawer()

  const userGuide = useUserGuide()
  const tasksManager = useTasksManager()

  const refreshProfile = async () => {
    const profileService = container.resolve<IProfileService>(Service.ProfileService)

    const token = await getAccessTokenWithDemo()
    const profileResponse = await profileService.getAccount(token)

    setProfile(profileResponse)
    setBaseCurrency(profileResponse.baseCurrency)

    return profileResponse
  }

  React.useEffect(() => {
    if (isDemoModeEnabled) {
      container.configureDemoDependencies()
      dispatch(DemoActions.switchToDemoModeOn())
    } else {
      container.configureBaseDependencies()
      dispatch(DemoActions.switchToDemoModeOff())
    }
  }, [ isDemoModeEnabled ])

  React.useEffect(() => {
    const refreshApplicationSettings = async () => {
      if (isAuthenticated || isDemoModeEnabled) {
        const profileResponse = await refreshProfile()

        if (profileResponse.brandNew) {
          setHiddenMode(false)

          const stepRedirects = []
          stepRedirects[StepName.Welcome] = Routes.DEMO_HOLDINGS
          stepRedirects[StepName.Holdings] = Routes.DEMO_HOLDINGS
          stepRedirects[StepName.Summary] = Routes.DEMO_HOLDINGS
          stepRedirects[StepName.HiddenMode] = Routes.DEMO_HOLDINGS
          stepRedirects[StepName.Transactions] = Routes.DEMO_TRANSACTIONS
          stepRedirects[StepName.Analytics] = Routes.DEMO_ANALYTICS
          stepRedirects[StepName.Prediction] = Routes.DEMO_FORECAST
          stepRedirects[StepName.Final] = Routes.DEMO_HOLDINGS

          userGuide.runUserGuide({
            displayWhenGuidePassedAlready: false,
            stepsRedirects: stepRedirects,
            redirectOnCompletion: Routes.HOLDINGS
          })
        }

        setEssentialsLoaded(true)
        setReleaseNotesRead(profileResponse.releaseNotesRead)
        setPendingSurveys(profileResponse.pendingSurveys)

        const authorizationProfile = extractAuthorizationProfile({
          isAdvisor: profileResponse.advisor,
          advisorRole: profileResponse.advisorRole,
          isAdvisoryClient: profileResponse.advisoryClient,
          tier: profileResponse.tierInformation.tier,
          subscription: profileResponse.subscription,
        })

        if (hasAccess(authorizationProfile, Permission.ViewMyTasks)) {
          await tasksManager.loadMyTasksAsync()
        }

        if (!isDemoModeEnabled) {
          sendUtmAnalytics()
        }
      }
    }

    refreshApplicationSettings()
  }, [ isAuthenticated, isDemoModeEnabled ])

  const passSurvey = (name: string) => {
    setPendingSurveys(pendingSurveys.filter(surveyName => surveyName !== name))
  }

  return {
    essentialsLoaded,
    baseCurrency,
    pendingSurveys,
    profile: {
      username: profile?.username ?? DefaultSettingsState.profile.username,
      isAdvisor: profile?.advisor ?? DefaultSettingsState.profile.isAdvisor,
      advisorRole: profile?.advisorRole ?? DefaultSettingsState.profile.advisorRole,
      isAdvisoryClient: profile?.advisoryClient ?? DefaultSettingsState.profile.isAdvisoryClient,
      organization: profile?.organization ?? DefaultSettingsState.profile.organization,
      tier: profile?.tierInformation?.tier ?? DefaultSettingsState.profile.tier,
      subscription: profile?.subscription ?? DefaultSettingsState.profile.subscription,
      brandNew: profile?.brandNew ?? DefaultSettingsState.profile.brandNew,
    },
    currencies: DefaultSettingsState.currencies,
    isHiddenMode: isHiddenModeEnabled,
    userGuide: {
      enabled: userGuide.enabled,
      config: userGuide.runConfiguration,
    },
    assetAllocationOpened: assetAllocationDrawer.isOpen,
    myHistoryOpened: myHistoryDrawer.isOpen,
    myTasksOpened: myTasksDrawer.isOpen,
    sharedHistoryOpened: sharedHistoryDrawer.isOpen,
    releaseNotesDialogOpened: releaseNotesDialog.isOpen,
    settingsDrawerOpened: settingsDrawer.isOpen,
    appBarExpanded,
    promoCode,
    manageSettings: {
      refreshProfile,
      passSurvey,
      changeBaseCurrency: setBaseCurrency,
      setHiddenMode: setHiddenMode,
      toggleHiddenMode: toggleHiddenMode,
      userGuide: {
        runUserGuide: userGuide.runUserGuide,
        completeUserGuide: userGuide.completeUserGuide
      },
      promo: {
        save: savePromoCode,
        clear: clearPromoCode,
      },
      assetAllocation: {
        open: assetAllocationDrawer.open,
        value: assetAllocationDrawer.value,
        close: assetAllocationDrawer.close,
      },
      myTasks: {
        open: myTasksDrawer.open,
        close: myTasksDrawer.close,
      },
      myHistory: {
        open: myHistoryDrawer.open,
        close: myHistoryDrawer.close,
      },
      sharedHistory: {
        open: sharedHistoryDrawer.open,
        value: sharedHistoryDrawer.value,
        close: sharedHistoryDrawer.close,
      },
      releaseNotesDialog: {
        open: releaseNotesDialog.open,
        close: releaseNotesDialog.close,
        value: releaseNotesDialog.value,
        releaseNotesRead: releaseNotesRead,
        readReleaseNotes: () => setReleaseNotesRead(true)
      },
      appBar: {
        toggle: toggleAppBar
      },
      settings: {
        open: settingsDrawer.open,
        close: settingsDrawer.close,
      },
    },
  }
}
