import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { Dictionary } from 'Shared/types'
import { BaseService } from 'Shared/api'
import {
  PortfolioAllocationType,
  IPortfolioService,
  ChangeSunburstAllocation,
  VisibilitySettingsRequest,
} from './portfolio-types'

export class PortfolioService extends BaseService implements IPortfolioService {
  public getVisibilitySettings = (token: string) => axios
    .get('portfolio/my/ui-preferences', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public updateVisibilitySettings = (token: string, request: VisibilitySettingsRequest) => axios
    .post('portfolio/my/ui-preferences', { payload: request }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public getAssetAllocation = (token: string, type: PortfolioAllocationType) => axios
    .get(`/portfolio/my/allocations/${type}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public updateAssetAllocation = (token: string, type: PortfolioAllocationType, request: Dictionary<number>) => axios
    .patch(`/portfolio/my/allocations/${type}`, request, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  // TODO: Temporary overhead, will be fixed after container to context injection
  public getMyAssetAllocation = (token: string, type: PortfolioAllocationType) => axios
    .get(`/portfolio/my/allocations/${type}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public updateMyAssetAllocation = (token: string, type: PortfolioAllocationType, request: Dictionary<number>) => axios
    .patch(`/portfolio/my/allocations/${type}`, request, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public deletePortfolios = (token: string) => axios
    .delete('/portfolio', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public changeSunburstAllocation = (token: string, firstLevelProperty: string, secondLevelProperty: string, request: ChangeSunburstAllocation) => axios
    .patch(`/portfolio/my/sunburst-allocation/${firstLevelProperty}/${secondLevelProperty}`, request, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public resetSunburstAllocation = (token: string, firstLevelProperty: string, secondLevelProperty: string) => axios
    .delete(`/portfolio/my/sunburst-allocation/${firstLevelProperty}/${secondLevelProperty}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public importPortfolio = (token: string, file: FormData) => axios
    .post('/portfolio/import', file, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
}
