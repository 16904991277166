import { Dictionary } from 'Shared/types'

export interface IPortfolioService {
  getVisibilitySettings(token: string): Promise<VisibilitySettingsResponse>
  updateVisibilitySettings(token: string, request: VisibilitySettingsRequest): Promise<void>
  getAssetAllocation(token: string, type: PortfolioAllocationType): Promise<AssetAllocation>
  updateAssetAllocation(token: string, type: PortfolioAllocationType, request: Dictionary<number>): Promise<void>
  getMyAssetAllocation(token: string, type: PortfolioAllocationType): Promise<AssetAllocation>
  updateMyAssetAllocation(token: string, type: PortfolioAllocationType, request: Dictionary<number>): Promise<void>
  changeSunburstAllocation(token: string, firstLevelProperty: string, secondLevelProperty: string, request: ChangeSunburstAllocation): Promise<void>
  resetSunburstAllocation(token: string, firstLevelProperty: string, secondLevelProperty: string): Promise<void>
  deletePortfolios(token: string): Promise<void>
  importPortfolio(token: string, file: FormData): Promise<ImportResponse>
}

export type VisibilitySettingsResponse = {
  portfolioKey: string
  visibleHoldingColumns: string[]
}

export type VisibilitySettingsRequest = {
  visibleHoldingColumns: string[]
}

export enum PortfolioAllocationType {
  Class = 'Class',
  Category = 'Category',
  Stock = 'Stock',
  Crypto = 'Crypto',
}

export type UpdateAssetAllocationRequest = {
  allocation: Dictionary<number>
  type: PortfolioAllocationType
}

export type AssetAllocation = {
  allocation: Dictionary<number>
}

export type ChangeSunburstAllocation = {
  path: string[]
  allocation: Dictionary<number>
}

export type ImportResponse = {
  createdAssetsCount: number
  createdHoldingKeys: string[]
  createdTransactionsCount: number
  validationErrors: string[]
}
