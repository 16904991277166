import { createSelector } from 'reselect'
import { chain } from 'lodash'
import { match } from 'ts-pattern'

import { HOLDING_TYPE } from 'Services/holdings'
import { UnitType } from 'Services/exchange'
import type { StoreState } from 'Shared/types'
import type { HoldingsState } from './holdings-types'

const holdingsStoreSelector = (state: StoreState): HoldingsState => state.holdings
const getHoldingsSelector = createSelector(holdingsStoreSelector, (state: HoldingsState) => state.holdings)
const getStockHoldings = createSelector(holdingsStoreSelector, state => state.holdings.filter(holding => holding.type === HOLDING_TYPE.Stock))
const getCryptoHoldings = createSelector(holdingsStoreSelector, state => state.holdings.filter(holding => holding.type === HOLDING_TYPE.Crypto))
const getCustomHoldings = createSelector(holdingsStoreSelector, state => state.holdings.filter(holding => holding.type === HOLDING_TYPE.Custom))
const getSelectedHoldingKeys = createSelector(holdingsStoreSelector, (state: HoldingsState) => state.selectedHoldings)

const getSelectedHoldingsSelector = createSelector(
  holdingsStoreSelector,
  state => {
    const selectedHoldingKeys = state.selectedHoldings

    if (state.holdings.length === 0 || state.selectedHoldings.length === 0) {
      return []
    }

    return state.holdings.filter(holding => selectedHoldingKeys.includes(holding.key))
  }
)

const getIsLoadingSelector = createSelector(holdingsStoreSelector, state => state.isLoading)
const getInitialLoadingState = createSelector(holdingsStoreSelector, state => state.isInitialLoadingCompleted)
const getIsSummaryLoadingSelector = createSelector(holdingsStoreSelector, state => state.isSummaryLoading)
const getSummarySelector = createSelector(holdingsStoreSelector, state => state.summary)

const holdingSelector = createSelector(
  holdingsStoreSelector,
  (_: StoreState, holdingKey: string | null) => holdingKey,
  (state, holdingKey) => state.holdings.find(holding => holding.key === holdingKey) || null
)

const holdingItemSelector = createSelector(
  [
    (state: StoreState): HoldingsState => state.holdings,
    (_: StoreState, params: { holdingKey: string | null }) => params.holdingKey,
    (_: StoreState, params: { ticker: string | null }) => params.ticker,
  ],
  (state: HoldingsState, holdingKey, ticker) => state
    .holdings.find(holding => holding.key === holdingKey)
    ?.items.find(item => item.ticker === ticker)
)

const unitTypeSelector = createSelector(
  holdingSelector,
  holding => match(holding?.type)
    .with(HOLDING_TYPE.Custom, () => UnitType.RegularCurrency)
    .with(HOLDING_TYPE.Crypto, () => UnitType.CryptoCurrency)
    .with(HOLDING_TYPE.Stock, () => UnitType.Stocks)
    .otherwise(() => null)
)

const getCategories = createSelector(
  holdingsStoreSelector,
  state => chain(state.holdings)
    .map(holding => holding.category)
    .compact()
    .uniq()
    .value())

const getCompanies = createSelector(
  holdingsStoreSelector,
  state => chain(state.holdings)
    .map(holding => holding.asset.company)
    .compact()
    .uniq()
    .value())

const getCountries = createSelector(
  holdingsStoreSelector,
  state => chain(state.holdings)
    .map(holding => holding.metadata.country)
    .compact()
    .uniq()
    .value())

const getCurrencies = createSelector(
  holdingsStoreSelector,
  state => chain(state.holdings)
    .map(holding => holding.currency)
    .compact()
    .uniq()
    .value())

const getTags = createSelector(
  holdingsStoreSelector,
  state => chain(state.holdings)
    .flatMap(holding => holding.tags)
    .compact()
    .uniq()
    .value())

const hasCountries = createSelector(holdingsStoreSelector, state => state.holdings.some(holding => holding.metadata.country))
const getFilterState = createSelector(holdingsStoreSelector, state => state.holdingsFilter)
const getViewSettings = createSelector(holdingsStoreSelector, state => state.viewSettings)
const getColumnViewSettings = createSelector(holdingsStoreSelector, state => state.viewSettings.visibleHoldingColumns)

const getViewHoldingDrawer = createSelector(holdingsStoreSelector, state => state.drawers.viewHolding)
const getCreateHoldingDrawer = createSelector(holdingsStoreSelector, state => state.drawers.createHolding)
const getEditHoldingDrawer = createSelector(holdingsStoreSelector, state => state.drawers.editHolding)
const getDeleteHoldingDrawer = createSelector(holdingsStoreSelector, state => state.drawers.deleteHolding)
const getArchiveHoldingDrawer = createSelector(holdingsStoreSelector, state => state.drawers.archiveHolding)
const getDepositWithdrawDrawer = createSelector(holdingsStoreSelector, state => state.drawers.depositWithdraw)
const getFixProfitLossDrawer = createSelector(holdingsStoreSelector, state => state.drawers.fixProfitLoss)
const getChangeTickerDrawer = createSelector(holdingsStoreSelector, state => state.drawers.changeTicker)
const getTransferDrawer = createSelector(holdingsStoreSelector, state => state.drawers.transfer)
const getBuySellDrawer = createSelector(holdingsStoreSelector, state => state.drawers.buySell)
const getImportHoldingsDrawer = createSelector(holdingsStoreSelector, state => state.drawers.importHoldings)
const getConnectAccountModal = createSelector(holdingsStoreSelector, state => state.drawers.connectAccount)
const getDisconnectAccountModal = createSelector(holdingsStoreSelector, state => state.drawers.disconnectAccount)
const getShareToChatbotModal = createSelector(holdingsStoreSelector, state => state.drawers.shareToChatbot)

const getBackdrop = createSelector(holdingsStoreSelector, state => state.globalBackdropEnabled)

export const HoldingSelectors = {
  getHoldingsSelector,
  getStockHoldings,
  getCryptoHoldings,
  getCustomHoldings,
  getSelectedHoldingKeys,
  getSelectedHoldingsSelector,
  getIsLoadingSelector,
  getInitialLoadingState,
  getIsSummaryLoadingSelector,
  getSummarySelector,
  getCategories,
  getCompanies,
  getCountries,
  getCurrencies,
  getTags,
  hasCountries,
  getFilterState,
  getColumnViewSettings,
  getViewSettings,
  holdingSelector,
  holdingItemSelector,
  unitTypeSelector,
  getBackdrop,
  drawers: {
    viewHolding: getViewHoldingDrawer,
    createHolding: getCreateHoldingDrawer,
    editHolding: getEditHoldingDrawer,
    deleteHolding: getDeleteHoldingDrawer,
    archiveHolding: getArchiveHoldingDrawer,
    depositWithdraw: getDepositWithdrawDrawer,
    fixProfitLoss: getFixProfitLossDrawer,
    changeTicker: getChangeTickerDrawer,
    transfer: getTransferDrawer,
    buySell: getBuySellDrawer,
    importHoldings: getImportHoldingsDrawer,
    connectAccount: getConnectAccountModal,
    disconnectAccount: getDisconnectAccountModal,
    shareToChatbot: getShareToChatbotModal,
  }
}
