import React from 'react'
import { FormControl, FormHelperText, InputLabel, Select, SelectProps } from '@mui/material'

import { localizeError } from 'Shared/utils'

const menuProps = {
  PaperProps: {
    style: {
      width: 250
    },
  },
}

interface Props<Value> extends Omit<SelectProps<Value>, 'error'> {
  error?: string | boolean
}

export const CustomSelect: (<Value = unknown>(props: Props<Value>) => JSX.Element) = props => {
  const { label, error, children, ...selectProps } = props

  return (
    <FormControl error={!!error} size="small" fullWidth>
      <InputLabel id={`select-label-${label}`}>{label}</InputLabel>
      <Select
        MenuProps={menuProps}
        labelId={`select-label-${label}`}
        label={label}
        {...selectProps}

        error={!!error}
      >
        {children}
      </Select>
      {!!error && <FormHelperText>{localizeError(error)}</FormHelperText>}
    </FormControl>
  )
}
