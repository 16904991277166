import React from 'react'
import { Stack } from '@mui/material'

import { LegendItem } from './LegendItem'

interface Item {
  key: string
  label: string
  color?: string
  onClick?: () => void
}

interface Props {
  items: Item[]
}

export const Legend: React.FC<Props> = ({ items }) => (
  <Stack
    direction="row"
    flexWrap="wrap"
    justifyContent={{ xs: 'flex-start', sm: 'center' }}
    columnGap={2}
    rowGap={0.5}>
    {items.map(item => (
      <LegendItem key={item.key} label={item.label} color={item.color} onClick={item.onClick} />
    ))}
  </Stack>
)
