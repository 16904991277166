import React, { Suspense, lazy } from 'react'
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import { ApplicationLoadingOverlay, ScrollToTop } from 'Shared/components'
import { Routes } from 'Shared/constants'
import { ViewModeProvider, ViewMode } from 'App/ViewMode'
import { PublicFunctionalAppBar } from 'App/NavigationBar'
import { StaticPageWrapper } from 'App/Router/ContentWrappers'
import { LandingFooter } from 'App/Footer/LandingFooter'
import { FireCalculatorWebAppStructuredData } from './googleStructuredData'

const PublicPredictionPage = lazy(() => import('Prediction/components/PublicPrediction.page'))

export const PublicForecastRoute = withTranslation()(({ t }) => (
  <>
    <Helmet>
      <title>FIREkit: FIRE calculator</title>
      <script type="application/ld+json">{JSON.stringify(FireCalculatorWebAppStructuredData)}</script>
      <meta name="title" content="FIREkit: FIRE calculator" />
      <meta name="description" content="Simplify your retirement planning! Our free FIRE calculator helps you track savings and project your financial future with ease. Try it now!" />
      <link rel="canonical" href={`${process.env.REACT_APP_HOST}${Routes.PUBLIC.FIRE_CALCULATOR}`} />
    </Helmet>

    <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
      <ScrollToTop behavior="auto">
        <PublicFunctionalAppBar />
        <StaticPageWrapper>
          <Suspense fallback={<ApplicationLoadingOverlay />}>
            <PublicPredictionPage />
            <LandingFooter />
          </Suspense>
        </StaticPageWrapper>
      </ScrollToTop>
    </ViewModeProvider>
  </>
))
