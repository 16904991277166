import React, { PropsWithChildren } from 'react'
import { SelectProps } from '@mui/material'
import { useField } from 'formik'

import { CustomSelect } from 'Shared/components/NonForm'

interface Props extends Omit<SelectProps, 'label' | 'name'> {
  name: string
  label: string
}

export const FormikSelect: React.FC<PropsWithChildren<Props>> = props => {
  const { name, label, children, ...selectProps } = props

  const [ field, meta ] = useField(name)
  const error = meta.touched && meta.error

  return (
    <CustomSelect label={label} error={error} {...selectProps} {...field}>
      {children}
    </CustomSelect>
  )
}
