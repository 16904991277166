import { useAuth0 } from '@auth0/auth0-react'
import { useContext } from 'react'
import { DemoContext } from './DemoContext'

export const useAuthWithDemo = () => {
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect, user } = useAuth0()
  const { demo } = useContext(DemoContext)

  const getAccessTokenWithDemo = async () => {
    if (!demo) {
      try {
        return await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
          }
        })
      } catch (e: any) {
        // NOTE: Intended to be used for localhost only
        if (e.message === 'Consent required') {
          await loginWithRedirect({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
              redirect_uri: window.location.origin,
              prompt: 'consent'
            }
          })
        }
      }
    }

    return Promise.resolve('DEMO')
  }

  return {
    getAccessTokenWithDemo,
    isAuthenticated,
    user,
  }
}
