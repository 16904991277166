import { Nullable, Dictionary } from 'Shared/types'
import {
  HoldingResponse,
  HOLDING_TYPE,
  Asset,
  HoldingMetaData,
  HoldingFinancialData,
  HoldingItem,
  CashAccount,
  ExternalConnection,
} from './holdings-types'

export class Holding implements HoldingResponse {
  key: string
  type: HOLDING_TYPE
  asset: Asset
  currency: string
  balance: Nullable<number>
  baseCurrencyNetWorth: number
  baseCurrencyCashNetWorth: number
  category: string
  createdOn: string
  lastBalanceChanges: string
  pinDateTime: Nullable<string>
  profitLossFromMonthStart: number
  metadata: HoldingMetaData
  financialData: HoldingFinancialData
  items: HoldingItem[]
  cashAccounts: CashAccount[]
  notes: string
  tags: string[]
  externalConnection: ExternalConnection

  private _snapshot: string

  constructor(response: HoldingResponse) {
    this.type = response.type
    this.asset = response.asset
    this.balance = response.balance
    this.baseCurrencyNetWorth = response.baseCurrencyNetWorth
    this.baseCurrencyCashNetWorth = response.baseCurrencyCashNetWorth
    this.category = response.category
    this.createdOn = response.createdOn
    this.currency = response.currency
    this.key = response.key
    this.lastBalanceChanges = response.lastBalanceChanges
    this.pinDateTime = response.pinDateTime
    this.profitLossFromMonthStart = response.profitLossFromMonthStart
    this.metadata = response.metadata
    this.financialData = response.financialData
    this.items = response.items ?? []
    this.cashAccounts = response.cashAccounts ?? []
    this.notes = response.notes
    this.tags = response.tags
    this.externalConnection = response.externalConnection

    this._snapshot = [
      this.asset.name,
      this.asset.company,
      this.category,
      this.balance,
      this.baseCurrencyNetWorth,
      ...(this.items ?? []).map(item => item.ticker),
      ...(this.tags ?? []),
    ].join('\x00').toSearchTerm()
  }

  public includes(term: string) {
    return this._snapshot.indexOf(term) !== -1
  }

  public getDefaultCashAccount() {
    if (this.type === HOLDING_TYPE.Custom) {
      return { currency: this.currency, balance: this.balance }
    }

    if (this.cashAccounts.length > 0) {
      const maxBalance = this
        .cashAccounts
        .map(ca => ca.balance)
        .reduce((max, value) => Math.max(max, value))

      return this.cashAccounts.find(ca => ca.balance === maxBalance) ?? null
    }

    return null
  }

  public buildItemsBalanceMap(): Dictionary<number> {
    return this.items.reduce((accum, item) => ({ ...accum, [ item.ticker ]: item.balance }), {})
  }

  public buildItemsAveragePriceMap(): Dictionary<number> {
    return this.items.reduce((accum, item) => ({ ...accum, [ item.ticker ]: item.defaultCurrencyDetails.averagePrice }), {})
  }

  public buildCashBalanceMap(): Dictionary<number> {
    if (this.type === HOLDING_TYPE.Custom) {
      return { [ this.currency ]: this.balance ?? 0 }
    }

    return this.cashAccounts.reduce((accum, ca) => ({ ...accum, [ ca.currency ]: ca.balance }), {})
  }

  public hasUnrecognizedItems(): boolean {
    return this.items.some(item => item.baseCurrencyDetails.price === 0)
  }
}
