import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import { IProfileService, UpdateProfileRequest, CreateSubscriptionRequest, UtmAnalytics } from './profile-types'

export class ProfileService extends BaseService implements IProfileService {
  public getAccount = (token: string) => axios
    .get('/profile', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public deleteAccount = (token: string) => axios
    .delete('/profile', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public updateAccount = (token: string, payload: UpdateProfileRequest) => axios
    .put('/profile', { payload }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public exportAccount = (token: string, fileName: string) => axios
    .get('/profile/export', {
      headers: { ...super.buildAuthHeader(token) },
      responseType: 'blob'
    })
    .then((response: AxiosResponse) => {
      this.downloadFile(response.data, fileName)

      return response?.data
    })

  public exportAiProfile = (token: string, holdingKeys: string[]) => axios
    .get(`/profile/ai/export${this.buildQueryString(holdingKeys)}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public readReleaseNotes = (token: string) => axios
    .patch('/profile/read-release-notes', {}, {
      headers: { ...super.buildAuthHeader(token) },
    })
    .then((response: AxiosResponse) => response?.data)

  public createSubscription = (token: string, payload: CreateSubscriptionRequest) => axios
    .post('/profile/subscription', { payload }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public cancelSubscription = (token: string) => axios
    .delete('/profile/subscription', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public getPromoCode = (token: string, promoCode: string) => axios
    .get(`/profile/promo-code/${promoCode}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
    .catch(error => error.response?.status === 404 ? null : Promise.reject(error))

  public saveUserAnalytics = (token: string, payload: UtmAnalytics) => axios
    .post('/profile/user-analytics', { payload }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  private downloadFile = (content: any, fileName: string) => {
    const link = document.createElement('a')
    link.target = '_blank'
    link.download = fileName
    link.href = URL.createObjectURL(
      new Blob([ content ])
    )
    link.click()

    return
  }

  protected buildQueryString = (holdingKeys: string[]) => {
    const queryString = holdingKeys.map(key => `holdingKeys=${key}`).join('&')

    if (queryString) {
      return '?' + queryString
    }

    return ''
  }
}
