import { deepOrange, green, grey, lime, orange, red } from '@mui/material/colors'

import {
  HOLDING_CLASS,
  HOLDING_LIQUIDITY,
  HOLDING_RISK,
  HOLDING_TERM,
  HOLDING_TYPE
} from 'Services/holdings'
import {
  DefaultUndefinedTranslationKey,
  DefaultUndefinedValue,
  HoldingClassTooltipTranslationKeys,
  HoldingClassTranslationKeys,
  HoldingLiquidityTranslationKeys,
  HoldingRiskTranslationKeys,
  HoldingTermTranslationKeys,
} from 'Shared/constants'
import { HoldingPrepopulatedFields } from 'Holdings/holdings-types'

export const HoldingsActionTypes = {
  GET_HOLDINGS: 'GET_HOLDINGS',
  GET_HOLDINGS_SUCCESS: 'GET_HOLDINGS_SUCCESS',
  GET_HOLDINGS_FAILURE: 'GET_HOLDINGS_FAILURE',
  CREATE_HOLDING: 'CREATE_HOLDING',
  ARCHIVE_HOLDING: 'ARCHIVE_HOLDING',
  DELETE_HOLDING: 'DELETE_HOLDING',
  GET_SUMMARY: 'GET_SUMMARY',
  GET_SUMMARY_SUCCESS: 'GET_SUMMARY_SUCCESS',
  GET_SUMMARY_FAILURE: 'GET_SUMMARY_FAILURE',
  SET_HOLDINGS_PAGE_LOADING: 'SET_HOLDINGS_PAGE_LOADING',
  TOGGLE_PIN_HOLDING: 'TOGGLE_PIN_HOLDING',
  PIN_HOLDING_SUCCESS: 'PIN_HOLDING_SUCCESS',
  SET_SELECTED_HOLDINGS: 'SET_SELECTED_HOLDINGS',
  RESET_STATE_HOLDINGS: 'RESET_STATE_HOLDINGS',
  SET_HOLDINGS_FILTER_CHANGED: 'SET_HOLDINGS_FILTER_CHANGED',
  OPEN_VIEW_SETTINGS: 'OPEN_VIEW_SETTINGS',
  CLOSE_VIEW_SETTINGS: 'CLOSE_VIEW_SETTINGS',
  SYNC_COLUMN_VIEW_SETTING: 'SYNC_COLUMN_VIEW_SETTING',
  SET_COLUMN_VIEW_SETTING_SUCCESS: 'SET_COLUMN_VIEW_SETTING_SUCCESS',
  SET_COLUMN_VIEW_SETTING: 'SET_COLUMN_VIEW_SETTING',
  GET_VIEW_SETTINGS: 'GET_VIEW_SETTINGS',

  drawers: {
    OPEN_VIEW_HOLDING_DRAWER: 'OPEN_VIEW_HOLDING_DRAWER',
    CLOSE_VIEW_HOLDING_DRAWER: 'CLOSE_VIEW_HOLDING_DRAWER',
    OPEN_CREATE_HOLDING_DRAWER: 'OPEN_CREATE_HOLDING_DRAWER',
    CLOSE_CREATE_HOLDING_DRAWER: 'CLOSE_CREATE_HOLDING_DRAWER',
    OPEN_EDIT_HOLDING_DRAWER: 'OPEN_EDIT_HOLDING_DRAWER',
    CLOSE_EDIT_HOLDING_DRAWER: 'CLOSE_EDIT_HOLDING_DRAWER',
    OPEN_DELETE_HOLDING_MODAL: 'OPEN_DELETE_HOLDING_MODAL',
    CLOSE_DELETE_HOLDING_MODAL: 'CLOSE_DELETE_HOLDING_MODAL',
    OPEN_ARCHIVE_HOLDING_MODAL: 'OPEN_ARCHIVE_HOLDING_MODAL',
    CLOSE_ARCHIVE_HOLDING_MODAL: 'CLOSE_ARCHIVE_HOLDING_MODAL',
    OPEN_DEPOSIT_WITHDRAW_DRAWER: 'OPEN_DEPOSIT_WITHDRAW_DRAWER',
    CLOSE_DEPOSIT_WITHDRAW_DRAWER: 'CLOSE_DEPOSIT_WITHDRAW_DRAWER',
    OPEN_FIX_PROFIT_LOSS_DRAWER: 'OPEN_FIX_PROFIT_LOSS_DRAWER',
    CLOSE_FIX_PROFIT_LOSS_DRAWER: 'CLOSE_FIX_PROFIT_LOSS_DRAWER',
    OPEN_TRANSFER_DRAWER: 'OPEN_TRANSFER_DRAWER',
    CLOSE_TRANSFER_DRAWER: 'CLOSE_TRANSFER_DRAWER',
    OPEN_BUY_SELL_DRAWER: 'OPEN_BUY_SELL_DRAWER',
    CLOSE_BUY_SELL_DRAWER: 'CLOSE_BUY_SELL_DRAWER',
    OPEN_CHANGE_TICKER_DRAWER: 'OPEN_CHANGE_TICKER_DRAWER',
    CLOSE_CHANGE_TICKER_DRAWER: 'CLOSE_CHANGE_TICKER_DRAWER',
    OPEN_IMPORT_HOLDINGS_MODAL: 'OPEN_IMPORT_HOLDINGS_MODAL',
    CLOSE_IMPORT_HOLDINGS_MODAL: 'CLOSE_IMPORT_HOLDINGS_MODAL',
    OPEN_CONNECT_ACCOUNT_MODAL: 'OPEN_CONNECT_ACCOUNT_MODAL',
    CLOSE_CONNECT_ACCOUNT_MODAL: 'CLOSE_CONNECT_ACCOUNT_MODAL',
    OPEN_DISCONNECT_ACCOUNT_MODAL: 'OPEN_DISCONNECT_ACCOUNT_MODAL',
    CLOSE_DISCONNECT_ACCOUNT_MODAL: 'CLOSE_DISCONNECT_ACCOUNT_MODAL',
    OPEN_SHARE_TO_CHATBOT_MODAL: 'OPEN_SHARE_TO_CHATBOT_MODAL',
    CLOSE_SHARE_TO_CHATBOT_MODAL: 'CLOSE_SHARE_TO_CHATBOT_MODAL',
  },

  SET_BACKDROP: 'SET_BACKDROP',
  DISCONNECT_HOLDING: 'DISCONNECT_HOLDING',
  REFRESH_HOLDING: 'REFRESH_HOLDING',
  RESUME_HOLDING: 'RESUME_HOLDING',
}

export const HoldingFields = {
  holdingName: 'holdingName',
  holdingKey: 'holdingKey',
  holdingCompany: 'holdingCompany',
  category: 'category',
  newCategory: 'newCategory',
  createNewCategory: 'createNewCategory',
  currency: 'currency',
  balance: 'balance',
  holdingBalance: 'holdingBalance',
  convertedBalance: 'convertedBalance',
  isConversionEnabled: 'isConversionEnabled',
  comment: 'comment',
  operationDate: 'operationDate',
  class: 'class',
  country: 'country',
  region: 'region',
  liquidity: 'liquidity',
  risk: 'risk',
  term: 'term',
  plannedProfitability: 'plannedProfitability',
  notes: 'notes',
  selectedOperation: 'selectedOperation',
  tags: 'tags',
  dueDate: 'dueDate',
}

export enum Column {
  PIN = 'PIN',
  TYPE = 'TYPE',
  HOLDING = 'HOLDING',
  RISK = 'RISK',
  LIQUIDITY = 'LIQUIDITY',
  TERM = 'TERM',
  CATEGORY = 'CATEGORY',
  BALANCE = 'BALANCE',
  BALANCE_BASE_CURRENCY = 'BALANCE_BASE_CURRENCY',
  TWR_PROFITABILITY = 'TWR_PROFITABILITY',
  XIRR_PROFITABILITY = 'XIRR_PROFITABILITY',
  EXPECTED_PROFITABILITY = 'EXPECTED_PROFITABILITY',
  UNREALIZED_PROFITABILITY = 'UNREALIZED_PROFITABILITY',
  REALIZED_PROFITABILITY = 'REALIZED_PROFITABILITY',
  TODAY_GAIN_PERCENT = 'TODAY_GAIN_PERCENT',
  TOTAL_PROFIT_LOSS = 'TOTAL_PROFIT_LOSS',
  UNREALIZED_PNL = 'UNREALIZED_PNL',
  REALIZED_PNL = 'REALIZED_PNL',
  TODAY_GAIN_VALUE = 'TODAY_GAIN_VALUE',
  MONTHLY_PNL = 'MONTHLY_PNL',
  COUNTRY = 'COUNTRY',
  DUE_DATE = 'DUE_DATE',
  ACTIONS = 'ACTIONS',

  SYSTEM_CONNECT = 'SYSTEM_CONNECT',
}

export const EmptyOption = {
  key: DefaultUndefinedTranslationKey,
  value: DefaultUndefinedValue,
  color: grey[500],
  order: 0
}

export const HoldingTermOptions = {
  [HOLDING_TERM.LessThanHalfOfYear]: {
    key: HoldingTermTranslationKeys[HOLDING_TERM.LessThanHalfOfYear],
    color: red[600],
    order: 1,
  },
  [HOLDING_TERM.HalfToOneYear]: {
    key: HoldingTermTranslationKeys[HOLDING_TERM.HalfToOneYear],
    color: deepOrange[500],
    order: 2,
  },
  [HOLDING_TERM.OneToFiveYears]: {
    key: HoldingTermTranslationKeys[HOLDING_TERM.OneToFiveYears],
    color: orange[500],
    order: 3,
  },
  [HOLDING_TERM.FiveToTenYears]: {
    key: HoldingTermTranslationKeys[HOLDING_TERM.FiveToTenYears],
    color: lime[500],
    order: 4,
  },
  [HOLDING_TERM.MoreThanTenYears]: {
    key: HoldingTermTranslationKeys[HOLDING_TERM.MoreThanTenYears],
    color: green[500],
    order: 5,
  },
}

export const HoldingClassOptions = {
  [HOLDING_CLASS.Cash]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.Cash],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.Cash],
    order: 1,
  },
  [HOLDING_CLASS.Securities]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.Securities],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.Securities],
    order: 2,
  },
  [HOLDING_CLASS.Bonds]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.Bonds],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.Bonds],
    order: 3,
  },
  [HOLDING_CLASS.RealEstate]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.RealEstate],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.RealEstate],
    order: 4,
  },
  [HOLDING_CLASS.Commodities]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.Commodities],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.Commodities],
    order: 5,
  },
  [HOLDING_CLASS.BusinessInvestments]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.BusinessInvestments],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.BusinessInvestments],
    order: 6,
  },
  [HOLDING_CLASS.VentureCapital]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.VentureCapital],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.VentureCapital],
    order: 7,
  },
  [HOLDING_CLASS.Cryptocurrency]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.Cryptocurrency],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.Cryptocurrency],
    order: 8,
  },
  [HOLDING_CLASS.PrivateDebt]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.PrivateDebt],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.PrivateDebt],
    order: 9,
  },
  [HOLDING_CLASS.PreciousMetals]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.PreciousMetals],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.PreciousMetals],
    order: 10,
  },
  [HOLDING_CLASS.Tangible]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.Tangible],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.Tangible],
    order: 11,
  },
  [HOLDING_CLASS.Intangible]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.Intangible],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.Intangible],
    order: 12,
  },
  [HOLDING_CLASS.Alternatives]: {
    key: HoldingClassTranslationKeys[HOLDING_CLASS.Alternatives],
    helpText: HoldingClassTooltipTranslationKeys[HOLDING_CLASS.Alternatives],
    order: 13,
  },
}

export const HoldingLiquidityOptions = {
  [HOLDING_LIQUIDITY.UrgentlyLiquid]: {
    key: HoldingLiquidityTranslationKeys[HOLDING_LIQUIDITY.UrgentlyLiquid],
    color: green[500],
    order: 1,
  },
  [HOLDING_LIQUIDITY.HighlyLiquid]: {
    key: HoldingLiquidityTranslationKeys[HOLDING_LIQUIDITY.HighlyLiquid],
    color: lime[500],
    order: 2,
  },
  [HOLDING_LIQUIDITY.MediumLiquid]: {
    key: HoldingLiquidityTranslationKeys[HOLDING_LIQUIDITY.MediumLiquid],
    color: orange[500],
    order: 3,
  },
  [HOLDING_LIQUIDITY.WeaklyLiquid]: {
    key: HoldingLiquidityTranslationKeys[HOLDING_LIQUIDITY.WeaklyLiquid],
    color: deepOrange[500],
    order: 4,
  },
  [HOLDING_LIQUIDITY.HardToLiquid]: {
    key: HoldingLiquidityTranslationKeys[HOLDING_LIQUIDITY.HardToLiquid],
    color: red[600],
    order: 5,
  },
}

export const HoldingRiskOptions = {
  [HOLDING_RISK.Min]: {
    key: HoldingRiskTranslationKeys[HOLDING_RISK.Min],
    color: green[500],
    order: 1,
  },
  [HOLDING_RISK.Low]: {
    key: HoldingRiskTranslationKeys[HOLDING_RISK.Low],
    color: lime[500],
    order: 2,
  },
  [HOLDING_RISK.Medium]: {
    key: HoldingRiskTranslationKeys[HOLDING_RISK.Medium],
    color: orange[500],
    order: 3,
  },
  [HOLDING_RISK.High]: {
    key: HoldingRiskTranslationKeys[HOLDING_RISK.High],
    color: deepOrange[500],
    order: 4,
  },
  [HOLDING_RISK.Max]: {
    key: HoldingRiskTranslationKeys[HOLDING_RISK.Max],
    color: red[600],
    order: 5,
  },
}

export const HoldingRiskOptionsWithEmpty = new Map(Object.entries({
  ...HoldingRiskOptions,
  [DefaultUndefinedValue]: EmptyOption
}))

export const HoldingTermOptionsWithEmpty = new Map(Object.entries({
  ...HoldingTermOptions,
  [DefaultUndefinedValue]: EmptyOption
}))

export const HoldingLiquidityOptionsWithEmpty = new Map(Object.entries({
  ...HoldingLiquidityOptions,
  [DefaultUndefinedValue]: EmptyOption
}))

export const HoldingTypeOptions = {
  [HOLDING_TYPE.Custom]: {
    key: 'holdings.types.custom',
  },
  [HOLDING_TYPE.Crypto]: {
    key: 'holdings.types.crypto',
  },
  [HOLDING_TYPE.Stock]: {
    key: 'holdings.types.stock',
  },
}

export const HoldingClassToDefaultValues = {
  [HOLDING_CLASS.Cash]: {
    plannedProfitability: 0,
    risk: HOLDING_RISK.Min,
    liquidity: HOLDING_LIQUIDITY.UrgentlyLiquid,
    term: HOLDING_TERM.LessThanHalfOfYear,
  },
  [HOLDING_CLASS.Securities]: {
    plannedProfitability: 8,
    risk: HOLDING_RISK.Medium,
    liquidity: HOLDING_LIQUIDITY.HighlyLiquid,
    term: HOLDING_TERM.FiveToTenYears,
  },
  [HOLDING_CLASS.Bonds]: {
    plannedProfitability: 3,
    risk: HOLDING_RISK.Low,
    liquidity: HOLDING_LIQUIDITY.MediumLiquid,
    term: HOLDING_TERM.OneToFiveYears,
  },
  [HOLDING_CLASS.RealEstate]: {
    plannedProfitability: 6,
    risk: HOLDING_RISK.Medium,
    liquidity: HOLDING_LIQUIDITY.HardToLiquid,
    term: HOLDING_TERM.MoreThanTenYears,
  },
  [HOLDING_CLASS.Commodities]: {
    plannedProfitability: 6,
    risk: HOLDING_RISK.High,
    liquidity: HOLDING_LIQUIDITY.MediumLiquid,
    term: HOLDING_TERM.OneToFiveYears,
  },
  [HOLDING_CLASS.BusinessInvestments]: {
    plannedProfitability: 12,
    risk: HOLDING_RISK.Max,
    liquidity: HOLDING_LIQUIDITY.HardToLiquid,
    term: HOLDING_TERM.MoreThanTenYears,
  },
  [HOLDING_CLASS.VentureCapital]: {
    plannedProfitability: 20,
    risk: HOLDING_RISK.Max,
    liquidity: HOLDING_LIQUIDITY.HardToLiquid,
    term: HOLDING_TERM.MoreThanTenYears,
  },
  [HOLDING_CLASS.Cryptocurrency]: {
    plannedProfitability: 25,
    risk: HOLDING_RISK.Max,
    liquidity: HOLDING_LIQUIDITY.HighlyLiquid,
    term: HOLDING_TERM.HalfToOneYear,
  },
  [HOLDING_CLASS.PrivateDebt]: {
    plannedProfitability: 10,
    risk: HOLDING_RISK.Medium,
    liquidity: HOLDING_LIQUIDITY.HardToLiquid,
    term: HOLDING_TERM.OneToFiveYears,
  },
  [HOLDING_CLASS.PreciousMetals]: {
    plannedProfitability: 4,
    risk: HOLDING_RISK.Medium,
    liquidity: HOLDING_LIQUIDITY.MediumLiquid,
    term: HOLDING_TERM.FiveToTenYears,
  },
  [HOLDING_CLASS.Tangible]: {
    plannedProfitability: 0,
    risk: HOLDING_RISK.Medium,
    liquidity: HOLDING_LIQUIDITY.HardToLiquid,
    term: HOLDING_TERM.FiveToTenYears,
  },
  [HOLDING_CLASS.Intangible]: {
    plannedProfitability: 6,
    risk: HOLDING_RISK.High,
    liquidity: HOLDING_LIQUIDITY.HardToLiquid,
    term: HOLDING_TERM.MoreThanTenYears,
  },
  [HOLDING_CLASS.Alternatives]: {
    plannedProfitability: 9,
    risk: HOLDING_RISK.High,
    liquidity: HOLDING_LIQUIDITY.MediumLiquid,
    term: HOLDING_TERM.OneToFiveYears,
  },
} as Record<HOLDING_CLASS, HoldingPrepopulatedFields>

export const ColumnOptions = {
  [Column.PIN]: { key: 'holdings.viewSettings.columns.pin', visible: true, disabled: false },
  [Column.TYPE]: { key: 'holdings.viewSettings.columns.type', visible: true, disabled: false },
  [Column.HOLDING]: { key: 'holdings.viewSettings.columns.nameAndCompany', visible: true, disabled: true },
  [Column.RISK]: { key: 'holdings.viewSettings.columns.risk', visible: true, disabled: false },
  [Column.LIQUIDITY]: { key: 'holdings.viewSettings.columns.liquidity', visible: true, disabled: false },
  [Column.TERM]: { key: 'holdings.viewSettings.columns.term', visible: true, disabled: false },
  [Column.CATEGORY]: { key: 'holdings.viewSettings.columns.categoryAndClass', visible: true, disabled: false },
  [Column.BALANCE]: { key: 'holdings.viewSettings.columns.balance', visible: true, disabled: false },
  [Column.BALANCE_BASE_CURRENCY]: { key: 'holdings.viewSettings.columns.balanceInBaseCurrency', visible: true, disabled: true },
  [Column.TWR_PROFITABILITY]: { key: 'holdings.viewSettings.columns.profitabilityTwr', visible: true, disabled: false },
  [Column.XIRR_PROFITABILITY]: { key: 'holdings.viewSettings.columns.profitabilityXirr', visible: false, disabled: false },
  [Column.EXPECTED_PROFITABILITY]: { key: 'holdings.viewSettings.columns.profitabilityExpected', visible: false, disabled: false },
  [Column.TOTAL_PROFIT_LOSS]: { key: 'holdings.viewSettings.columns.pnlTotal', visible: false, disabled: false },
  [Column.UNREALIZED_PROFITABILITY]: { key: 'holdings.viewSettings.columns.profitabilityUnrealized', visible: false, disabled: false },
  [Column.UNREALIZED_PNL]: { key: 'holdings.viewSettings.columns.pnlUnrealized', false: true, disabled: false },
  [Column.REALIZED_PROFITABILITY]: { key: 'holdings.viewSettings.columns.profitabilityRealized', visible: false, disabled: false },
  [Column.REALIZED_PNL]: { key: 'holdings.viewSettings.columns.pnlRealized', visible: false, disabled: false },
  [Column.MONTHLY_PNL]: { key: 'holdings.viewSettings.columns.pnlMonthly', visible: true, disabled: false },
  [Column.TODAY_GAIN_PERCENT]: { key: 'holdings.viewSettings.columns.profitabilityToday', visible: true, disabled: false },
  [Column.TODAY_GAIN_VALUE]: { key: 'holdings.viewSettings.columns.pnlToday', visible: false, disabled: false },
  [Column.COUNTRY]: { key: 'holdings.viewSettings.columns.country', visible: true, disabled: false },
  [Column.DUE_DATE]: { key: 'holdings.viewSettings.columns.dueDate', visible: true, disabled: false },
  [Column.ACTIONS]: { key: 'holdings.viewSettings.columns.actions', visible: true, disabled: true },
}
