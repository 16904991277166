import React from 'react'
import { useTranslation } from 'react-i18next'
import { List } from '@mui/material'

import { PricingPlan } from 'Pricing/pricing-types'
import { PricingCardSectionHeader } from './PricingCardSectionHeader'
import { PricingCardSectionPrice } from './PricingCardSectionPrice'
import { PricingCardSectionFeatures } from './PricingCardSectionFeatures'
import { PricingCardSectionActions } from './PricingCardSectionActions'
import * as S from './styles'

interface Props {
  title: string
  forWhomText: string
  choseButtonText: string
  localePath: string,
  featureKeys: string,
  hintKeys: string
  pricingPlan: PricingPlan
  onChosePlan?: () => void
  highlighted?: boolean
  disabled?: boolean
}

export const PricingCard: React.FC<Props> = ({
  title,
  forWhomText,
  choseButtonText,
  localePath,
  featureKeys,
  hintKeys,
  pricingPlan,
  onChosePlan,
  highlighted = false,
  disabled = false,
}) => {
  const { t } = useTranslation()

  const features = React.useMemo(() => {
    const keys: string[] = t(featureKeys, { returnObjects: true })
    const hints: string[] = t(hintKeys, { returnObjects: true })

    return keys.map((key, index) => ({
      key: t(`${localePath}.${key}`),
      hint: hints.includes(key) ? t(`${localePath}.${hints[index]}-hint`) : undefined }))
  }, [ t ])

  return (
    <S.TierCard highlighted={highlighted}>
      <PricingCardSectionHeader
        title={title}
        forWhomText={forWhomText}
        promoActive={pricingPlan.periodPromoActive}
      />

      <PricingCardSectionPrice pricingPlan={pricingPlan} />

      <List sx={{ flexGrow: 1 }}>
        <PricingCardSectionFeatures features={features} />
      </List>

      <PricingCardSectionActions
        onChosePlan={onChosePlan}
        disabled={!onChosePlan || disabled}
        ctaButtonText={choseButtonText}
      />
    </S.TierCard>
  )
}
