import { RenewalPeriod } from 'Services/profile'
import { PricingType } from './pricing-types'

export const FreePricingCardConfiguration = {
  type: PricingType.Free,
  localePath: 'pricing.features.free',
  features: 'pricing.features.free.keys',
  hints: 'pricing.features.free.hints',
}

export const PremiumPricingCardConfiguration = {
  type: PricingType.Premium,
  price: {
    perMonth: 5.00,
    perYear: 60,
    defaultMonthlyDiscountedPrice: null,
    defaultYearlyDiscountedPrice: 49,
  },
  localePath: 'pricing.features.premium',
  features: 'pricing.features.premium.keys',
  hints: 'pricing.features.premium.hints',
}

export const AdvisorPricingCardConfiguration = {
  type: PricingType.Free,
  localePath: 'pricing.features.advisor',
  features: 'pricing.features.advisor.keys',
  hints: 'pricing.features.advisor.hints',
}

export const FreePricingPlan = {
  [RenewalPeriod.Month]: {
    renewalPeriod: RenewalPeriod.Month,
    firstPeriodPrice: 0,
    nextPeriodPrice: 0,
    originalPrice: 0,
    periodPromoActive: false,
    autoRenew: true,
    firstPeriodOnly: false,
  },
  [RenewalPeriod.Year]: {
    renewalPeriod: RenewalPeriod.Year,
    firstPeriodPrice: 0,
    nextPeriodPrice: 0,
    originalPrice: 0,
    periodPromoActive: false,
    autoRenew: true,
    firstPeriodOnly: false,
  },
  [RenewalPeriod.Day]: {
    renewalPeriod: RenewalPeriod.Day,
    firstPeriodPrice: 0,
    nextPeriodPrice: 0,
    originalPrice: 0,
    periodPromoActive: false,
    autoRenew: false,
    firstPeriodOnly: false,
  },
  [RenewalPeriod.None]: {
    renewalPeriod: RenewalPeriod.None,
    firstPeriodPrice: 0,
    nextPeriodPrice: 0,
    originalPrice: 0,
    periodPromoActive: false,
    autoRenew: false,
    firstPeriodOnly: false,
  }
}

export const YearDiscountTextKey = 'pricing.period.yearlyDiscount'
